import React from "react";
import InternalLink from "../InternalLink";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { giveMeOldOrNewImage } from "../NoritexImage";
import { Container, Section, Heading } from "../../sdk/Layout";
import { Items, Item } from "./style";

function InfoSection({ config }) {
    if (config != null && config.length <= 0) return null;

    return (
        <Section>
            <Container>
                <Heading isCentered>
                    <h3>{config[0].title}</h3>
                </Heading>

                <Items>
                    {config[0].posts.map((index, i) => (
                        <Item
                            key={`info-section-${giveMeOldOrNewImage(
                                index.img
                            )}`}
                        >
                            <div>
                                <h2>{index.title}</h2>
                                <p>{index.paragraph}</p>
                                <InternalLink {...index.href}>
                                    {index.cta}
                                </InternalLink>
                            </div>

                            <figure>
                                <LazyLoadImage
                                    src={giveMeOldOrNewImage(index.img)}
                                    alt={index.alt}
                                />
                            </figure>
                        </Item>
                    ))}
                </Items>
            </Container>
        </Section>
    );
}

export default InfoSection;
