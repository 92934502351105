import styled from "styled-components";

export const Title = styled.h2`
    font-size: 28px;
    font-weight: 800;
    color: ${(props) => props.theme.black};
    margin-bottom: 16px;
    text-transform: uppercase;
    text-align: center;
`;

export const Cards = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    > a {
        width: 360px;
        overflow: hidden;
    }
`;

export const Card = styled.div`
    position: relative;
    height: 360px;

    img {
        width: 360px;
        height: 280px;
        object-fit: cover;
        object-position: center;
        transition: all 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    h3 {
        margin-bottom: 8px;
    }
    h3, p {
        color: ${(props) => props.theme.black};
    }
    
    > div {
        background: ${(props) => props.theme.white};
        position: absolute;
        top: 280px;
        left: 0;
        width: 100%;
        padding-top: 8px;
    }

    &:hover {
        img {
            @media (min-width: 769px) {
                width: calc(360px + 20px);
                height: calc(280px + 20px);
            }
        }
        h3 {
            text-decoration: underline;
        }
    }
`;

export const Items = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    > a {
        cursor: pointer;
        flex-grow: 1;
        position: relative;
        border-radius: ${props => props.theme.radio};
        overflow: hidden;
        width: calc(50% - 8px);
        min-height: 250px;
    }
    @media (max-width: 768px) {
        > a {
            width: 100%;
        }
        gap: 8px;
    }
`;

export const Item = styled.div`
    &:before {
        content: "";
        display: block;
        padding-top: 60%;
    }
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: all 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    > div {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 32px;
        padding-right: 60px;
        border-radius: ${props => props.theme.radio};
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        background: ${props => props.theme.blueLightAlpha};
        transition: all 200ms linear;
        span {
            margin: 0;
            padding: 0;
            font-size: 14px;
            color: ${props => props.theme.gray};
            letter-spacing: 0;
            margin-bottom: 10px;
            display: block;
        }
        h3 {
            text-transform: uppercase;
            font-size: 32px;
            color: ${props => props.theme.black};
            font-weight: 800;
        }
        p {
            margin: 0;
            padding: 0;
            margin-top: 10px;
            font-size: 16px;
            color: ${props => props.theme.primaryDark};
        }
        .arrow {
            position: absolute;
            bottom: 8px;
            right: 8px;
            svg {
                display: block;
            }
        }
        @media (max-width: 980px) {
            padding: 24px;
            padding-right: 40px;
            .arrow svg {
                width: 32px;
                height: 32px;
                bottom: 4px;
            }
            h3 {
                font-size: 20px;
            }
            p {
                font-size: 14px;
            }
        }
        @media (max-width: 768px) {
            p {
                // display: none;
            }
        }
    }
    &:hover {
        > div {
            background: ${props => props.theme.blueLight};
        }
        img {
            @media (min-width: 769px) {
                width: 103%;
                height: 103%;
            }
        }
    }
`;
