import React from "react";
import IdealImage from "react-ideal-image";

function NoritexImage({ image, index, className, alt }) {
	index = index || 0;
	const placeholder = {
		lqip: ``,
	};

	if (image.length === 0) return null;

	let width = 0;
	let height = 0;
	let src = "";
	let srcSet = [];

	// backward compatible with older image api
	if (image.img == null && image.length > 0) {
		src = image[index].url;
	} else if (image && image.img.length > 0) {
		src = image.img[index];
		// src = src.replace(
		// 	"https://www.noritex.com",
		// 	"https://ntxcloudfront.s3.amazonaws.com"
		// );
		srcSet.push({ width: 300, src });
	}

	if (image[index] && image[index].lqip) {
		placeholder.lqip = image[index].lqip;
	}

	if (image[index] && image[index].size) {
		width = image[index].size.width;
		height = image[index].size.height;
	}

	if (image[index] && image[index].thumb) {
		srcSet.push({ width: 300, src: image[index].thumb });
		srcSet.push({ width: 500, src: image[index].url });
	}

	if (image[index] && image[index].webp) {
		srcSet.push({ width: 500, src: image[index].webp });
	}

	if (image[index] && image[index].alt !== "") {
		alt = image[index].alt;
	}
	return (
		<IdealImage
			src={src}
			className={className}
			width={width}
			height={height}
			placeholder={placeholder}
			srcSet={srcSet}
			alt={alt}
			threshold={2000}
		/>
	);
}

export const giveMeOldOrNewImage = image => {
	let src = null;
	if (image && image.img == null && image.length > 0) {
		src = image[0].url;
	} else if (image && image.img && image.img.length > 0) {
		src = image.img[0];
	}

	return src;
};

export default NoritexImage;
