import styled from "styled-components";

export const Items = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const Item = styled.div`
    flex-basis: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 4%;
    border-left: solid 1px ${props => props.theme.borderColor};
    border-right: solid 1px ${props => props.theme.borderColor};
    min-height: 600px;

    figure {
        margin-top: 20px;
        padding-top: 50%;
        position: relative;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    & + & {
        @media (min-width: 541px) {
            margin-left: -1px;
        }
    }

    h2 {
        padding: 0;
        margin: 0;
        font-family: futura;
        font-size: 32px;
        color: ${props => props.theme.primaryDark};
        letter-spacing: 0;
        line-height: 37px;
        margin-bottom: 20px;
    }
    p {
        padding: 0;
        margin: 0;
        font-size: 18px;
        color: ${props => props.theme.gray};
        letter-spacing: 0;
        line-height: 32px;
        margin-bottom: 20px;
    }
    a {
        font-weight: bold;
        font-family: futura;
        font-size: 18px;
        color: ${props => props.theme.black};
        letter-spacing: 0;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }

    @media (max-width: 768px) {
        min-height: 400px;
        h2 {
            font-size: 20px;
            line-height: 24px;
        }
        p {
            font-size: 14px;
            line-height: 20px;
        }
    }

    @media (max-width: 540px) {
        flex-basis: 100%;
        min-height: auto;
        & + & {
            margin-top: 30px;
        }
        h2 {
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 10px;
        }
        a {
            font-size: 16px;
        }
    }
`;
