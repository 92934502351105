import React, { Component } from "react";
import styled from "styled-components";
// import ItemsCarousel from "react-items-carousel";
import InternalLink from "../InternalLink";
import { giveMeOldOrNewImage } from "../NoritexImage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import renderIf from "../../utils/renderIf";

const GlobalStyleSlider = styled.div`
    .slideshow-slide-b {
        position: relative;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        height: 560px;
        overflow: hidden;
        background-color: rgb(216, 216, 216);
        background-size: cover;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center top;
        padding: 20px;
        &--img {
            // display: block;
            // width: 100vw;
            // height: 560px;
            @media screen and (max-width: 767px) {
                height: auto;
                width: 100%;
            }
            // img {
            // 	display: block;
            // 	width: 100vw;
            // 	height: 100vw;
            // 	object-fit: cover;
            // }
        }
        &--text {
            margin-top: 40px;
            margin-bottom: 5px;
            display: flex;
            color: @white;
            font-size: 12px;
            span {
                font-size: 12px;
                color: @white;
            }
            @media screen and (max-width: 767px) {
                display: block;
                margin: 40px auto 0;
                color: @black;
                text-align: center;
                span {
                    color: @black;
                }
            }
        }
        &--content {
            display: flex;
            align-items: flex-start;
            -webkit-box-pack: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            width: 100%;
            max-width: 1124px;
            height: 100%;
            h1 {
                text-align: left;
                font-size: 70px;
                line-height: 72px;
                letter-spacing: 3px;
                max-width: 900px;
                color: rgb(255, 255, 255);
            }
            @media screen and (max-width: 767px) {
                padding: 30px 20px;
                h1 {
                    color: @white;
                    font-size: 34px;
                    text-align: center;
                    line-height: 48px;
                    letter-spacing: 3px;
                    font-weight: bold;
                    max-width: 373px;
                    display: block;
                    margin: auto;
                }
            }
        }
        &--cta {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            width: 100%;
            max-width: 770px;
            margin-top: 50px;
            gap: 32px;
            overflow: hidden;
            a {
                letter-spacing: 1px;
                background-color: rgb(255, 255, 255);
                color: rgb(51, 51, 51);
                text-transform: unset;
                display: flex;
                flex-direction: column;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                height: 70px;
                width: 100%;
                font-weight: 200;
                font-size: 16px;
                border-width: 1px;
                border-style: solid;
                border-color: rgb(255, 255, 255);
                border-image: initial;
            }
            @media screen and (max-width: 767px) {
                display: block;
                a {
                    height: 60px;
                    width: 100%;
                    color: @white;
                    &:first-of-type {
                        margin-bottom: 26px;
                        color: @white;
                        border: none;
                        background-color: @black;
                    }
                    &:last-of-type {
                        background-color: transparent;
                        border: 1px solid @white;
                    }
                }
            }
        }
        @media screen and (max-width: 1439px) and (min-width: 1024px) {
            height: 500px;
        }
        @media screen and (max-width: 767px) {
            height: auto;
            padding: 0;
            background-color: #112c55;
            background-size: 0;

            // background-color: rgb(249, 246, 243);
        }
    }
    .slideshow--image-background {
        position: absolute;
        height: 560px;
        left: 0;
        top: 0;
        bottom: 0;
        @media screen and (max-width: 767px) {
        }
    }
`;

const SlideImage = (props) => {
    const [isServer, setServer] = React.useState(true);

    React.useEffect(() => {
        setServer(false);
    }, [typeof window === "undefined"]);

    return (
        <div
            className="slideshow-slide-b"
            style={
                props.width > 767 && !isServer
                    ? {
                          backgroundImage: `url("${props.img}")`,
                      }
                    : { backgroundImage: "none" }
            }
        >
            {props.children}
        </div>
    );
};

const SlideContent = (index) => {
    // const [width, setWidth] = React.useState(1200);

    const width = index.width;

    // React.useEffect(() => {}, []);

    let img = giveMeOldOrNewImage(index.img);
    let logo = giveMeOldOrNewImage(index.logo);
    let srcSmall = giveMeOldOrNewImage(index.img_mobile);

    if (width < 768 && srcSmall != null && srcSmall !== "") {
        img = srcSmall;
    }

    if (width < 768) {
        // pass
    } else if (width < 800) {
        img = `${img}?d=700x700`;
    } else if (width < 1100) {
        img = `${img}?d=1100x1100`;
    }

    return (
        <div>
            <SlideImage img={img} width={width} h1={index.h1}>
                {index.h1 ? (
                    <div className=" slideshow-slide-b--content ">
                        {renderIf(logo == null || logo === "")(
                            <h1 className="slideshow-slide-heading responsive-h1">
                                {index.h1}
                            </h1>
                        )}
                        {renderIf(logo != null && logo !== "")(
                            <LazyLoadImage
                                className="slideshow-slide-logo"
                                image={logo}
                                index={0}
                                alt={index.alt || index.h1}
                            />
                        )}
                        <div className="slideshow-slide-b--cta">
                            {index.link1 !== "" && index.link1 != null ? (
                                <InternalLink {...index.link1}>
                                    {index.cta1}
                                </InternalLink>
                            ) : null}
                            {index.link2 !== "" && index.link2 != null ? (
                                <InternalLink {...index.link2}>
                                    {index.cta2}
                                </InternalLink>
                            ) : null}
                        </div>
                        <div
                            className="slideshow-slide-b--text"
                            dangerouslySetInnerHTML={{
                                __html: index.p,
                            }}
                        />
                    </div>
                ) : null}
            </SlideImage>

            <img
                className="slideshow-slide-b--img is-visible-768 "
                srcSet={` 
                        ${img}?d=300x300 300w,
                        ${img}?d=400x400 400w,
                        ${img}?d=600x600 600w,
                        ${img}?d=700x700 600w
                        `}
                alt={index.alt || index.h1}
            />
        </div>
    );
};

class SliderB extends React.PureComponent {
    state = {
        activeItemIndex: 0,
        width: 600,
    };

    timeout = null;

    timeoutDuration = 8000;

    componentDidMount() {
        this.updateDimensions();
        if (typeof window !== "undefined") {
            window.addEventListener("resize", this.updateDimensions);
        }
    }

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.updateDimensions);
        }
    }

    updateDimensions = () => {
        if (typeof window !== "undefined")
            this.setState({
                width: Math.max(
                    document.documentElement.clientWidth,
                    window.innerWidth || 0
                ),
            });
    };

    nextSlide = () => {
        let activeItemIndex = this.state.activeItemIndex + 1;
        if (activeItemIndex >= this.props.config.length) {
            activeItemIndex = 0;
        }

        this.setState({ activeItemIndex });
    };

    lastSlide = () => {
        let activeItemIndex = this.state.activeItemIndex - 1;
        if (activeItemIndex <= -1) {
            activeItemIndex = this.props.config.length - 1;
        }
        this.setState({ activeItemIndex });
    };

    changeActiveItem = (activeItemIndex) => {
        this.setState({ activeItemIndex });
    };

    getSlides = (slides, activeItemIndex) => {
        return slides.map((index, i) => {
            if (i > 0) return null;
            return (
                <SlideContent
                    {...index}
                    key={"slides-b-" + i}
                    width={this.state.width}
                />
            );
        });
    };

    render() {
        const { config } = this.props;
        const { activeItemIndex } = this.state;

        if (config != null && config.length <= 0) return null;
        const count = config.length;

        return (
            <GlobalStyleSlider className="slider-b" id="sliderb">
                {this.getSlides(config, activeItemIndex)}
            </GlobalStyleSlider>
        );
    }
}

export default SliderB;
