import React from "react";
import InternalLink from "../InternalLink";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { giveMeOldOrNewImage } from "../NoritexImage";
import { Container, Section } from "../../sdk/Layout";
import useTranslation from "../../../lang/hooks/useTranslation";
import Icons from "../Icons2";
import { Items, Item, Cards, Card, Title } from "./style";

function Mosaics({ config }) {
	const [_t] = useTranslation();

    if (config != null && config.length <= 0) return null;

    return (
        <Section>
            <Container large>
                <Title>{_t("ourBrands")}</Title>
                <Cards>
                    {config.map((index, i) => (
                        <InternalLink
                            to={`/${index.cta}/brand`}
                            key={`mosaic-${giveMeOldOrNewImage(index.img)}`}
                        >
                            <Card>
                                <LazyLoadImage
                                    src={`${giveMeOldOrNewImage(
                                        index.img
                                    )}?d=700x700`}
                                    srcSet={` 
                                    ${giveMeOldOrNewImage(
                                        index.img
                                    )}?d=300x300 300w,
                                    ${giveMeOldOrNewImage(
                                        index.img
                                    )}?d=400x400 400w,
                                    ${giveMeOldOrNewImage(
                                        index.img
                                    )}?d=700x700 700w
                                    
                                    `}
                                    alt={index.alt}
                                />
                                <div>
                                    {/* <span>{index.subTitle}</span> */}
                                    <h3>
                                        {index.title}
                                    </h3>
                                    <p>{index.p}</p>

                                    {/* <div className="arrow">
                                        <svg width={40} height={40} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="24.7547" cy="24.0188" r={24} fill="#4577F4" />
                                            <path d="M28.6297 19.9132L23.2938 14.5773L19.3027 18.5684L24.6386 23.9044L18.9349 29.6081L22.8216 33.4948L32.5164 23.7999L28.6297 19.9132Z" fill="white" />
                                        </svg>
                                    </div> */}

                                    {/* <Icons icon="long-arrow-right"></Icons> */}
                                </div>
                            </Card>
                        </InternalLink>
                    ))}
                </Cards>
            </Container>
        </Section>
    );
}

export default Mosaics;
