import styled from "styled-components";
import { Section } from "../../sdk/Layout";

export const MainHeading = styled.div`
	padding-bottom: 30px;
	text-align: center;
	h2 {
		padding: 0;
		margin: 0;
		font-family: futura;
		font-weight: 500;
		font-size: 30px;
		color: ${(props) => props.theme.primaryDarkMode};
		letter-spacing: 6.5px;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 10px;
	}
	p {
		margin: 0;
		padding: 0;
		font-size: 18px;
		color: ${(props) => props.theme.primaryDark};
		letter-spacing: 0;
		text-align: center;
		line-height: 27px;
		width: 100%;
		max-width: 460px;
		margin: auto;
	}

	@media (max-width: 768px) {
		h2 {
			font-size: 22px;
			letter-spacing: 3px;
		}
		p {
			font-size: 16px;
			line-height: 18px;
		}
	}
`;

export const Brands = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	> a {
		border-radius: ${(props) => props.theme.radio};
		overflow: hidden;
		/* flex-grow: 1; */
		width: calc(33.33333% - 10.66666px);
		position: relative;
		background-color: #000;
		display: block;
		cursor: pointer;
	}
	@media (max-width: 980px) {
		gap: 8px;
		> a {
			width: calc(50% - 4px);
		}
	}
`;

export const Brand = styled.div`
	border-radius: ${(props) => props.theme.radio};
	overflow: hidden;
	position: relative;
	&:before {
		content: "";
		display: block;
		padding-top: 80%;
	}
	> img {
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
		width: 100%;
		height: 100%;
		transition: all 200ms linear;
	}

	> div {
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		top: 0;
		padding: 10px;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		background: linear-gradient(
			180deg,
			rgba(14, 86, 217, 0) 0%,
			rgba(14, 86, 217, 0.6) 100%
		);
		img {
			width: 200px;
			height: 100px;
			object-fit: contain;
			transition: all 200ms linear;
			display: block;
			@media (max-width: 725px) {
				max-width: 80%;
			}
		}
		p {
			transition: all 50ms ease-in;
			margin: 0;
			padding: 0;
			font-size: 0;
			color: #ffffff;
			letter-spacing: 0;
			text-align: center;
			line-height: 22px;
			@media (max-width: 480px) {
				display: none;
			}
		}
	}
	// &:hover {
	//     > img {
	//         opacity: 0.25;
	//     }
	//     > div {
	//         img {
	//             @media (min-width: 481px) {
	//                 margin-bottom: 10px;
	//             }
	//         }
	//         p {
	//             font-size: 16px;
	//             @media (max-width: 1215px) and (min-width: 981px) {
	//                 font-size: 14px;
	//                 line-height: 17px;
	//             }
	//             @media (max-width: 768px) {
	//                 font-size: 14px;
	//                 line-height: 17px;
	//             }
	//         }
	//     }
	// }
`;
