import styled from "styled-components";
import { Container, Section, Columns, Column, Title } from "../../sdk/Layout";

export const Wrapper = styled(Section)`
    background-color: ${props => props.theme.grayLight};
`;

export const Cols = styled(Columns)`
    margin-left: 0;
    flex-wrap: wrap;
    @media (max-width: 1215px) {
        margin: 1px;
    }
`;

export const Left = styled(Column)`
    width: 350px;
    background-color: ${props => props.theme.white};
    padding: 5%;
    display: flex;
    align-items: center;
    text-align: left;

    ${Title} {
        font-family: futura;
        font-size: 31px;
        color: ${props => props.theme.primaryDarkMode};
        letter-spacing: 0;
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        color: ${props => props.theme.primaryDark};
        letter-spacing: 0;
        line-height: 25px;
    }

    @media (max-width: 1215px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        & ${Title} {
            font-size: 20px;
            margin-bottom: 10px;
        }
        p {
            font-size: 14px;
            line-height: 20px;
        }
    }
`;

export const Right = styled(Column)`
    padding-top: 0;
    padding-bottom: 0;
    @media (max-width: 1215px) {
        width: 100%;
        padding: 0;
        margin-top: 10px;
    }
`;

export const Items = styled.div`
    margin-right: -0.5%;
    margin-left: -0.5%;
    margin-top: -0.5%;
    margin-bottom: -0.5%;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        justify-content: center;
    }
`;

export const Item = styled.div`
    background-color: ${props => props.theme.white};
    margin: 0.5%;
    width: 49%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    figure {
        width: 50%;
        height: 100%;
        position: relative;
        flex: none;
        &:before {
            display: block;
            content: "";
            padding-top: 100%;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    div {
        width: 50%;
        padding: 20px;
        & ${Title} {
            padding: 0;
            margin-bottom: 10px;
        }
        ul {
            padding: 0;
            margin: 0;
            li {
                margin: 0;
                padding: 0;
                line-height: 16px;
                + li {
                    margin-top: 6px;
                }
                a {
                    font-size: 12px;
                    color: ${props => props.theme.primaryDark};
                    letter-spacing: 0;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media (max-width: 980px) and (min-width: 769px) {
        width: 24%;
        flex-direction: column;
        figure {
            width: 100%;
        }
        div {
            width: 100%;
            padding: 15px;
            ul {
                li {
                    line-height: 12px;
                    a {
                        font-size: 11px;
                    }
                    + li {
                        margin-top: 3px;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        width: 99%;
        max-width: 480px;
        figure {
            width: 30%;
            height: 100%;
            &:before {
                /*padding-top: 50%;*/
            }
        }
        div {
            width: 70%;
            padding: 15px;
            ul {
                li {
                    line-height: 12px;
                    + li {
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    @media (max-width: 420px) {
        div {
            ul {
                li {
                    line-height: 10px;
                    a {
                        font-size: 11px;
                    }
                }
            }
        }
    }
`;
