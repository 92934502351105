import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import InternalLink from "../InternalLink";
import { giveMeOldOrNewImage } from "../NoritexImage";
import { Container, Title } from "../../sdk/Layout";
import { Wrapper, Cols, Left, Right, Items, Item } from "./style";
import { useTranslation } from "../../../lang";

function MenuList({ config }) {
    const [_t] = useTranslation();
    if (config != null && config.length <= 0) return null;

    return (
        <Wrapper>
            <Container>
                <Cols>
                    <Left isNarrow>
                        <div>
                            <Title as="h3" size="5">
                                {_t("ourCategories")}
                            </Title>
                            <p>{_t("ourCategoriesHelp")}</p>
                        </div>
                    </Left>
                    <Right>
                        <Items>
                            {config.map((index, i) => (
                                <Item key={`menu-list-${i}`}>
                                    <figure>
                                        <LazyLoadImage
                                            src={`${giveMeOldOrNewImage(
                                                index.image
                                            )}?d=200x200`}
                                            alt={index.title}
                                        />
                                    </figure>
                                    <div>
                                        <Title as="h4" size="6">
                                            {index.title}
                                        </Title>
                                        <ul>
                                            {index.links.map((link, j) => {
                                                if (link.fieldType) {
                                                    return (
                                                        <li
                                                            key={`${index.title}-link-${j}`}
                                                        >
                                                            <InternalLink
                                                                {...link.fieldType}
                                                            >
                                                                {
                                                                    link
                                                                        .fieldType
                                                                        .title
                                                                }
                                                            </InternalLink>
                                                        </li>
                                                    );
                                                }
                                                return (
                                                    <li
                                                        key={`${index.title}-link-${j}`}
                                                    >
                                                        <InternalLink
                                                            {...link.link}
                                                        >
                                                            {link.link.title}
                                                        </InternalLink>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </Item>
                            ))}
                        </Items>
                    </Right>
                </Cols>
            </Container>
        </Wrapper>
    );
}

export default React.memo(MenuList);
