import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import InternalLink from "../InternalLink";
import { Brands, Brand } from "./style";
import { Container, Heading2, Section } from "../../sdk/Layout";
import { giveMeOldOrNewImage } from "../NoritexImage";

function BrandsComponent({ config, _t }) {
	if (config != null && config.length <= 0) return null;

	return (
		<Section>
			<Container large>
				<Heading2>
					<h2 className="title">{config[0].title}</h2>
					<p className="description">{config[0].paragraph}</p>
				</Heading2>

				<Brands>
					{config[0] && config[0].brands
						? config[0].brands.map((index, i) => (
								<InternalLink
									key={`brand-${i}`}
									{...index.href}>
									<Brand>
										<div>
											{index.logo && index.logo.img ? (
												<LazyLoadImage
													src={giveMeOldOrNewImage(
														index.logo,
													)}
													alt={index.alt}
													delayTime={600}
													threshold={400}
												/>
											) : null}
											{/* <p>{index.paragraph}</p> */}
										</div>
										<LazyLoadImage
											src={`${giveMeOldOrNewImage(
												index.img,
											)}?d=600x600`}
											alt={index.alt}
										/>
									</Brand>
								</InternalLink>
						  ))
						: null}
				</Brands>
			</Container>
		</Section>
	);
}
export default BrandsComponent;
